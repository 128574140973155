<i18n>
{
  "ru": {
    "title": "Авторизация"
  },
  "he": {
    "title": "הרשאה"
  }
}
</i18n>

<template>
  <div class="">
    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      :color="snackbar.color"
      centered
    >
      <div class="text-center" v-html="snackbar.text"></div>
    </v-snackbar>

    <v-dialog
      :value="true"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 mb-3">
          {{ $t('title') }}
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid">
            <v-row class="mb-5">
              <template v-for="(item, index) in propsAuth">
                <v-col
                  cols="12"
                  :key="`prop-item-info-${index}`"
                >
                  <template v-if="item.type === 'text'">
                    <v-text-field
                      v-model="item.value"
                      :label="item.name"
                      :disabled="item.disabled"
                      :required="item.required"
                      :rules="item.rules"
                      color="teal"
                    ></v-text-field>
                  </template>

                  <template v-if="item.type === 'password'">
                    <v-text-field
                      v-model="item.value"
                      :label="item.name"
                      :disabled="item.disabled"
                      :required="item.required"
                      :rules="item.rules"
                      color="teal"
                      :type="item.type"
                    ></v-text-field>
                  </template>
                </v-col>
              </template>
            </v-row>

            <v-row class="mt-5">
              <v-col
                cols="auto"
              >
                <v-btn
                  color="teal"
                  dark
                  :loading="btnLoading"
                  @click="login()"
                >{{ $t('btn.login') }}</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data: (vm) => ({
    valid: false,
    btnLoading: false,
    snackbar: {},
    props: [
      {
        name: vm.$t('props.login'),
        code: 'login',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'auth',
      },
      {
        name: vm.$t('props.password'),
        code: 'password',
        type: 'password',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'auth',
      },
    ],
  }),
  computed: {
    propsAuth() {
      return this.props.filter((item) => item.group === 'auth');
    },
  },
  methods: {
    login() {
      if (this.valid) {
        this.btnLoading = true;
        const params = new FormData();

        this.props.forEach((prop) => {
          params.append(prop.code, prop.value);
        });

        this.$store.dispatch('login', params).then((response) => {
          const { success } = response.data.data;

          if (success) {
            this.$router.push('/');
          } else {
            this.btnLoading = false;
            this.snackbar = {
              show: true,
              color: (success) ? 'green darken-3' : 'red darken-3',
              text: response.data.data.message,
            };
          }
        });
      } else {
        this.snackbar = {
          show: true,
          color: 'red darken-3',
          text: this.$t('errorEmptyProps'),
        };
      }
    },
  },
};
</script>

<style lang="scss">
  .auth {
    width: 300px;
  }
</style>
